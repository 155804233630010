import React from "react";
import JSONPretty from "react-json-pretty";
import { JasonResponseContainer } from "./CommonComponents";
import { Chip } from "@mui/material";
import { DotIcon } from "../../assets/svgs";

interface JsonDisplayComponentProps {
  width: string;
  height: string;
  data: any;
  status: string;
  containerWidth?: string;
}

const JsonDisplayComponent = ({
  width = "",
  height = "",
  containerWidth = "fit-content",
  data,
  status,
}: JsonDisplayComponentProps) => {
  return (
    <>
      {data && (
        <div>
          <div
            style={{
              background: "#163545",
              padding: "20px",
              width: containerWidth,
              position: "relative",
              maxWidth: "1000px",
            }}
          >
            <Chip
              label={status}
              icon={DotIcon}
              sx={{
                position: "absolute",
                right: "40px",
                width: "90px",
                height: "25px",
                background: "#A2F0EC",
                borderRadius: "8px",
                color: "#006D88",
                fontSize: "14px",
                fontWeight: "700",
                lineHeight: "17px",
                fontFamily: "DM Sans",
                zIndex: 999,
              }}
            />
            <JasonResponseContainer w={width} h={height}>
              <JSONPretty
                data={data}
                mainStyle="line-height: 1.3;color: white !important;background: #163545 !important; overflow: auto; line-height: 20px !important; font-size: 14px !important; border-left: 5px solid #44DAD3; padding-left: 20px;"
                keyStyle="color: #FFFFFF !important;"
                valueStyle="color: #F78331 !important; margin-left: 5px !important;"
                booleanStyle="color: #44DAD3 !important; margin-left: 5px !important;"
                stringStyle="color: #F78331 !important; margin-left: 5px !important;"
                errorStyle="line-height: 1.3;color:rgba(248, 248, 242, 1);background: #1e1e1e; overflow: auto;"
              />
            </JasonResponseContainer>
          </div>
        </div>
      )}
    </>
  );
};

export default JsonDisplayComponent;
