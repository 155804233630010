import react, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { InputBase } from "@mui/material";
import Alerttriangle from "../../assets/Alert-Triangle.svg";
import { IconButton, InputAdornment } from "@mui/material";
import {
  OpenEye,
  ClosedEye,
  CopyClipboardIcon,
  SearchIcon,
  CalenderIcon,
} from "../../assets/svgs";
import { Text14pxBold } from "./CommonComponents";

interface InputTextFieldProps {
  type?: "text" | "number" | "password" | "date";
  value: any;
  error: any;
  onChange: any;
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  maxLength?: number;
  info?: string;
  height?: string;
  copyToClipboard?: boolean;
  searchable?: boolean;
  isCalendar?: boolean;
  inputProps?: any;
  className?: string;
  endAdornment?: any;
  startIcon?: any;
  showErrorInBottom?: boolean;
  disabledStyle?: string;
  startAdornmentStyle?: string;
  endAdornmentStyle?: object;
  inputStyle?: object;
}

function InputTextField({
  type = "text",
  value,
  error,
  onChange,
  name,
  label,
  required = false,
  disabled = false,
  placeholder = "",
  maxLength = 0,
  info = "",
  height = "60px",
  copyToClipboard = false,
  searchable = false,
  isCalendar = false,
  inputProps = {},
  className = "",
  endAdornment = null,
  startIcon = null,
  showErrorInBottom = false,
  disabledStyle = "",
  startAdornmentStyle = "",
  endAdornmentStyle = {},
  inputStyle = {},
}: InputTextFieldProps) {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isCopy, setIsCopy] = useState<boolean>(false);

  useEffect(() => {
    if (isCopy) {
      const timeId = setTimeout(() => {
        setIsCopy(false);
      }, 9000);

      return () => {
        clearTimeout(timeId);
      };
    }
  }, [isCopy]);

  return (
    <div className="w-full">
      <div className="flex">
        {label && (
          <InputLabel className="flex-1 w-fit min-h-[16px]">
            {label || ""}
            {required && <span style={{ color: "#b23352" }}> *</span>}
          </InputLabel>
        )}
        {error[name] && error[name].trim() && !showErrorInBottom && (
          <InputErrortext className="flex">
            <img src={Alerttriangle} alt="required" className="mr-[10px]" />
            {error[name]}
          </InputErrortext>
        )}
      </div>

      <InputBase
        data-testid={label}
        className={`${
          className ? className : ""
        } w-full px-3 text-base font-medium text-[#163545] !outline-none
        font-DMSans placeholder:text-[#9BA8B0] ${
          error[name] ? "bg-[#F4E1E5]" : "bg-[#F3F5F6]"
        } ${disabledStyle && disabled ? disabledStyle : ""}`}
        sx={{ borderRadius: "1px", height: height, ...inputStyle }}
        name={name}
        placeholder={placeholder}
        type={type === "password" ? (showPassword ? "text" : "password") : type}
        value={value[name]}
        onChange={(e: any) => {
          if (maxLength && e.target.value.length > maxLength) {
            return;
          }
          onChange(e);
        }}
        required={required}
        disabled={disabled}
        autoComplete="off"
        startAdornment={
          searchable && (
            <InputAdornment
              position="end"
              className={`mr-2 ${startAdornmentStyle}`}
            >
              {startIcon ? startIcon : SearchIcon}
            </InputAdornment>
          )
        }
        endAdornment={
          endAdornment ? (
            endAdornment
          ) : type === "password" ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => {
                  setShowPassword(!showPassword);
                  // navigator.clipboard.writeText(value[name]);
                }}
                edge="end"
              >
                {showPassword ? ClosedEye : OpenEye}
              </IconButton>
            </InputAdornment>
          ) : copyToClipboard ? (
            <InputAdornment
              position="end"
              sx={{
                ...endAdornmentStyle,
              }}
              className={`${isCopy && "!text-[#0494B4]"}`}
            >
              {isCopy && (
                <div className="text-sm mr-2 w-[170px] font-semibold">
                  {"Copied to your clipboard"}
                </div>
              )}

              <IconButton
                aria-label="copy to clipboard"
                onClick={() => {
                  setIsCopy(true);
                  navigator.clipboard.writeText(value[name] || "");
                }}
                edge="start"
                className={`${isCopy && "!text-[#0494B4]"}`}
                sx={{ "&:hover": { background: "none" }, color: "inherit" }}
              >
                <Text14pxBold className="mr-[5px] !text-inherit">
                  Copy
                </Text14pxBold>{" "}
                {CopyClipboardIcon}
              </IconButton>
            </InputAdornment>
          ) : (
            isCalendar && (
              <InputAdornment position="end" className="ml-2">
                {CalenderIcon}
              </InputAdornment>
            )
          )
        }
        inputProps={inputProps}
      />
      {info && <InputInfotext className="mt-1">{info}</InputInfotext>}
      {error[name] && error[name].trim() && showErrorInBottom ? (
        <WalletInputErrortext className="flex">
          {error[name]}
        </WalletInputErrortext>
      ) : (
        <></>
      )}
    </div>
  );
}

export const InputLabel = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #555555;
  margin-bottom: 15px;
`;

export const InputErrortext = styled.p`
  height: 16px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: #b23352;
`;

export const InputInfotext = styled.p`
  height: 16px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 300;
  color: #9ba8b0;
`;

export const WalletInputErrortext = styled.p`
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: #b23352;
  margin-top: 10px;
`;

export default InputTextField;
