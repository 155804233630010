import axios, { AxiosInstance } from "axios";
import BaseAPIClient from "../../../http/api";
import { AppConstants } from "../../../constants/AppConstants";

const globalHeaders: any = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
};

const API_BASE_URL: string = process.env.REACT_APP_API_URL || "http://localhost";
const API = BaseAPIClient?.axiosInstance;

export const getBatchFiles = async (reqData:any)=>{
    const headers = { ...globalHeaders };
    let uri: string = `${API_BASE_URL}${AppConstants.GET_BATCH}`;
    const response = await API.post(uri,reqData, {
      headers,
    });
    return response;
}

export const getAllBatchFilesAdmin = async (reqData:any)=>{
  const headers = { ...globalHeaders };
  let uri: string = `${API_BASE_URL}${AppConstants.GET_BATCHES_ADMIN}`;
  const response = await API.post(uri,reqData, {
    headers,
  });
  return response;
}

export const getApiKeysAndProducts = async ()=>{
    const headers = { ...globalHeaders };
    let uri: string = `${API_BASE_URL}${AppConstants.GET_API_KEY_AND_PRODUCTS}`;
    const response = await API.get(uri, {
      headers,
    });
    return response;
}

export const submitBatch = async (formData:FormData)=>{
    const headers = {  'content-type': 'multipart/form-data', "Access-Control-Allow-Origin": "*",};
    let uri: string = `${API_BASE_URL}${AppConstants.SUBMIT_BATCH}`;
    const response = await API.post(uri,formData, {
      headers,
    });
    return response;
}