import React, { useEffect, useState } from "react";
import TrestleTable, {
  TableHeaderProps,
} from "../../Shared/TrestleTable/TrestleTable";
import { ComponentLabelHeader } from "../../Shared/CommonComponents";
import LoaderComponent from "../../Shared/LoaderComponent";
import { getApiKeysAndProducts, getBatchFiles } from "./BatchUploadService";
import { HTTP_STATUS } from "../../../http/constants/http.status";
import { useDispatch } from "react-redux";
import { setToast } from "../../../app/toastReducer";
import { AppConstants } from "../../../constants/AppConstants";
import NoDataFound from "../../Shared/NoDataFound";
import moment from "moment";
import { getUTCDate } from "../../../utils/helpers";
import TrestleButton from "../../Shared/TrestleButton";
import BatchUploadPopUp from "./BatchUploadPopUp";
import { Box } from "@mui/material";

export interface IBatchFilesResponse {
  inputFile: string;
  createdAt: Date;
  productName: string;
  apiKey: string;
  status: string;
  outputFile: string;
  completedAt: Date;
}

const BatchUpload = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const [batches, setBatches] = useState<IBatchFilesResponse[]>([]);

  const [apiKeysProducts, setApiKeysProducts] = useState<{}>({});

  const [pageNumber, setPageNumber] = useState(0);

  const [totalRecords, setTotalRecords] = useState(0);

  const [noOfRecords, setNoOfRecords] = useState(20);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    callGetBatchAPI();
  }, []);

  useEffect(() => {
    callGetBatchAPI();
  }, [pageNumber, noOfRecords]);

  const callGetBatchAPI = async () => {
    let reqData: any = { pageNo: pageNumber, pageSize: noOfRecords };

    setLoading(true);
    const response = await getBatchFiles(reqData);
    const apiKeysResponse = await getApiKeysAndProducts();
    setLoading(false);

    const { status = 401, data = {} } = response || {};

    if (apiKeysResponse.status === HTTP_STATUS.HTTP_OK) {
      setApiKeysProducts(apiKeysResponse.data.data);
    }

    if (status === HTTP_STATUS.HTTP_OK) {
      setBatches(data.data.batchExecutions);
      setTotalRecords(data.data.totalRecords);
    } else {
      const { details } = data;
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: details,
        })
      );
    }
  };

  const headCells: readonly TableHeaderProps[] = [
    {
      id: "id",
      numeric: false,
      disablePadding: false,
      label: "Batch Id",
      width: "10%",
      isCustomElements: true,
      customElements: (ele: any) => {
        return "Batch-" + ele.id;
      },
      sortable: true,
    },
    {
      id: "inputFile",
      numeric: false,
      disablePadding: false,
      label: "Input File Name",
      width: "25%",
      isCustomElements: false,
      customElements: (ele: any) => {},
      sortable: true,
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date Uploaded",
      width: "15%",
      isCustomElements: true,
      customElements: (ele: any) => {
        return (
          <span>
            {moment(getUTCDate(ele.createdAt || "")).format(
              "MM/DD/YYYY HH:mm:ss"
            )}
          </span>
        );
      },
      sortable: false,
    },
    {
      id: "productName",
      numeric: false,
      disablePadding: false,
      label: "Product Name",
      width: "15%",
      isCustomElements: false,
      customElements: (ele: any) => {},
      sortable: false,
    },
    {
      id: "apiKey",
      numeric: false,
      disablePadding: false,
      label: "API Key",
      width: "10%",
      isCustomElements: true,
      customElements: (ele: any) => {
        return ele.apiKey.substring(0, 9) + "............";
      },
      sortable: true,
    },
    {
      id: "completedAt",
      numeric: false,
      disablePadding: false,
      label: "Completion Date",
      width: "15%",
      isCustomElements: true,
      customElements: (ele: any) => {
        return (
          <span>
            {ele.completedAt
              ? moment(getUTCDate(ele.completedAt || "")).format(
                  "MM/DD/YYYY HH:mm:ss"
                )
              : "-"}
          </span>
        );
      },
      sortable: true,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
      width: "10%",
      isCustomElements: false,
      customElements: (ele: any) => {},
      sortable: true,
    },
  ];

  return (
    <>
      <LoaderComponent loader={loading} />

      <BatchUploadPopUp
        open={isOpen}
        apiKeysAndProducts={apiKeysProducts}
        setIsLoading={setLoading}
        callGetBatchAPI={callGetBatchAPI}
        setOpen={setIsOpen}
      />

      <div className="px-[50px] py-[30px] flex">
        <Box>
          <ComponentLabelHeader className="flex-1">
            Batch Upload
          </ComponentLabelHeader>
          <div className="flex gap-[8px] items-center">
            <p>
              Process multiple records for a single API by uploading a CSV file.
              Efficiently handle up to 10,000 records per batch for one-time or
              recurring data processing needs. Results are typically available
              within an hour, streamlining your workflow for large-scale
              operations on individual APIs.
            </p>
            <div className="uploadButton pr-[50px]">
              <TrestleButton
                onClick={() => {
                  setIsOpen(true);
                }}
                label="Batch Upload"
                type="primary"
                className="!h-[40px] !leading-[40px] !p-0 !min-w-[150px] !max-w-[150px] mt-auto ml-auto"
              />
            </div>
          </div>
        </Box>
      </div>

      <div className="pr-[50px] min-h-full bg-white">
        <div className="px-[50px]  h-full">
          <div className="flex py-[45px] justify-between">
            {batches.length > 0 ? (
              <TrestleTable
                isPaginated={true}
                headCells={headCells}
                rows={batches}
                setPageNumber={setPageNumber}
                totalNoOfRecords={totalRecords}
                setNoOfRecords={setNoOfRecords}
              />
            ) : (
              !loading && <NoDataFound className="mt-52" />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BatchUpload;
