import { useState, useEffect } from "react";
import {
  TextValue,
  UserDetailSubHeader,
  UserNameText,
} from "../Shared/CommonComponents";
import { useDispatch } from "react-redux";
import { setTokens, setUserData } from "../../app/authReducer";
import Auth from "@aws-amplify/auth";
import { DownArrow } from "../../assets/svgs";
import InputTextField from "../Shared/InputTextField";
import {
  formatName,
  formatPhone,
  validatePhone,
} from "../../utils/validations";
import { AppConstants } from "../../constants/AppConstants";
import TrestleButton from "../Shared/TrestleButton";
import styled from "@emotion/styled";
import {
  getUser,
  signOutUser,
  updateCompanyName,
  updateUser,
} from "../../http/authentication-service";
import { HTTP_STATUS } from "../../http/constants/http.status";
import { setToast } from "../../app/toastReducer";
import ChangePassword from "../ChangePassword/ChangePassword";
import DialogPopup from "../Shared/DialogPopup";
import { TabTextComponent } from "../Shared/ComponentDrawer/componentDrawer";
import Teams from "./Teams";
import Cookies from "js-cookie";
import { Box, Typography } from "@mui/material";
import { UpdateUserStatus } from "../../http/admin-service";
import axios from "axios";
import FindMaxioCustomerID from "../Maxio/findMaxioCustomerID";
import {
  CognitoIdentityProviderClient,
  RevokeTokenCommand,
  GlobalSignOutCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import BillingDetailsTab from "./BillingDetails";
import { persistor } from "../../app/store";

interface UserDetailsProps {
  setLoading: Function;
  userData: any;
  isAdmin: boolean;
  closeDrawer?: any;
  setInitialFormdate?: any;
}

function UserDetails({
  setLoading,
  userData,
  isAdmin = false,
  closeDrawer = () => {},
  setInitialFormdate = () => {},
}: UserDetailsProps) {
  const dispatch = useDispatch();

  let userType = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    other: "",
    company: "",
    team: "",
    maxioCustomerId: "",
    id: "",
  };

  const [showDialog, setDialog] = useState<boolean>(false);

  const [data, setData] = useState<any>(userType);

  const [formData, setFormData] = useState<any>(userType);

  const [isChange, setChange] = useState<boolean>(false);

  const [selectMaxioCustomerID, setSelectMaxioCustomerID] =
    useState<boolean>(false);

  const [selectedTab, setSelectedTab] = useState<
    "DETAILS" | "TEAMS" | "BILLING_DETAILS"
  >("DETAILS");

  const [isDisableUserOpen, setIsDisableUserOpen] = useState<boolean>(false);

  const [teamChange, setTeamChange] = useState<boolean>(false);

  //set data from props to form data on page load
  useEffect(() => {
    if (userData.email === undefined && userData.includes("@")) {
      getUserData(userData);
    } else if (
      userData?.email !== undefined &&
      userData?.email?.includes("@")
    ) {
      getUserData(isAdmin ? userData.email : "");
    }
    const {
      id = "",
      company = null,
      name: teamName = "",
    } = userData?.team || {};
    const { id: companyId = "", name: companyName = "" } = company || {};
    setFormData({
      ...setFormValues,
      team: teamName,
      company: companyName,
      companyId: companyId,
      teamId: id,
    });
    setData(setFormValues);
  }, []);

  const revokeToken = async () => {
    const clientId = process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID;
    const refreshToken: any = localStorage.getItem("refreshToken");

    const cognitoIdp = new CognitoIdentityProviderClient({
      region: process.env.REACT_APP_COGNITO_REGION,
      // apiVersion: "2016-04-18",
    });

    const params: any = {
      Token: refreshToken,
      ClientId: clientId,
    };

    const revokeTokenCommand = new RevokeTokenCommand(params);
    const revokeTokenResponse = await cognitoIdp.send(revokeTokenCommand);
  };

  const logout = async () => {
    try {
      const signOutResponse = await signOutUser();
      const { status = 401 } = signOutResponse || {};
      if (status === HTTP_STATUS.HTTP_OK) {
        const response = await Auth.signOut({ global: true });
        await revokeToken();
        const newDate = new Date();
        setCookie(
          "developerportal",
          `${
            formData.email
          }|loggedout|${newDate.getUTCHours()}:${newDate.getUTCMinutes()}:${newDate.getUTCSeconds()}`
        );
        persistor.purge();
        localStorage.clear();
        sessionStorage.clear();
        dispatch(setTokens({ idToken: "", refreshToken: "" }));
        window.location.reload();
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  const getUserData = async (email: string) => {
    setLoading(true);
    const response = await getUser(email);
    setLoading(false);
    const { status = 401, data = {} } = response || {};
    if (email) {
      if (status === HTTP_STATUS.HTTP_OK) {
        if (isAdmin === false) dispatch(setUserData(data.data));
        const {
          id = "",
          company = null,
          name: teamName = "",
        } = data?.data?.team || {};
        const { id: companyId = "", name: companyName = "" } = company || {};
        setFormData({
          ...data.data,
          team: teamName,
          company: companyName,
          companyId: companyId,
          teamId: id,
        });
        setData({
          ...data.data,
          team: teamName,
          company: companyName,
          companyId: companyId,
          teamId: id,
        });
      } else {
        const { details } = data;
        dispatch(
          setToast({
            open: true,
            severity: AppConstants.TOAST_ERROR,
            msg: details,
          })
        );
      }
    } else {
      if (response?.data?.data.entityStatus == "ACTIVE") {
        if (status === HTTP_STATUS.HTTP_OK) {
          if (isAdmin === false) dispatch(setUserData(data.data));
          const {
            id = "",
            company = null,
            name: teamName = "",
          } = data?.data?.team || {};
          const { id: companyId = "", name: companyName = "" } = company || {};
          setFormData({
            ...data.data,
            team: teamName,
            company: companyName,
            companyId: companyId,
            teamId: id,
          });
          setData({
            ...data.data,
            team: teamName,
            company: companyName,
            companyId: companyId,
            teamId: id,
          });
        } else {
          const { details } = data;
          dispatch(
            setToast({
              open: true,
              severity: AppConstants.TOAST_ERROR,
              msg: details,
            })
          );
        }
      } else {
        logout();
      }
    }
  };

  //set data from userData to form data
  const setFormValues = () => {
    return {
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      phone: userData.phone,
      company: userData.company,
      team: userData.team,
      maxioCustomerId: userData.maxioCustomerId,
      id: userData.id,
    };
  };
  //check for any change in form
  useEffect(() => {
    if (JSON.stringify(data) == JSON.stringify(formData)) setChange(false);
    else setChange(true);
  }, [formData, data]);

  //on input value change
  const onInputValueChange = (event: any) => {
    let { name, value } = event.target;
    if (value.trim()) {
      if (name === "phone" && value?.trim()) {
        let t = formatPhone(value);
        value = t || formData.phone;
      }
      if (name === "team" || name === "company") {
        value = formatName(value);
        setTeamChange(true);
      }
      if (name === "firstName" || name === "lastName") {
        value = formatName(value);
      }
    }
    setFormData((initialValues: {}) => {
      return {
        ...initialValues,
        [name]: value,
      };
    });
  };

  const [formError, setFormError] = useState<any>({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    company: "",
    team: "",
    maxioCustomerId: "",
    id: "",
  });

  const setCookie = (name: string, value: string) => {
    Cookies.set(name, value, {
      expires: 1,
      domain: ".trestleiq.com",
      secure: true,
      sameSite: "none",
      // path: "/",
    });
  };

  //check for any input errors
  const checkInputErrors = () => {
    let isError: boolean = true;

    let { firstName, lastName, phone } = formData;

    let tempError = {
      firstName: firstName.trim() ? "" : AppConstants.REQUIRED_FILED_ERROR,
      lastName: lastName.trim() ? "" : AppConstants.REQUIRED_FILED_ERROR,
      phone: validatePhone(phone),
    };

    if (tempError.phone || tempError.firstName || tempError.lastName) {
      isError = false;
    }
    setFormError({ ...tempError });
    return isError;
  };

  //on cancel reset form
  const onCancel = () => {
    setFormData(data);
    setFormError({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      company: "",
      team: "",
      maxioCustomerId: "",
      id: "",
    });
  };
  const saveTeamChanges = async () => {
    const response = await updateCompanyName(
      formData.companyId,
      formData.company,
      formData.teamId,
      formData.team
    );
    const { status = 401, data = {} } = response || {};
    if (status === HTTP_STATUS.HTTP_OK) {
      if (userData.email === undefined && userData.includes("@")) {
        getUserData(userData);
      }
      setTeamChange(false);
      setChange(false);
      setInitialFormdate();
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_SUCCESS,
          msg: AppConstants.PROFILE_UPDATED,
        })
      );
      setLoading(false);
    } else {
      const { message } = data;
      setLoading(false);
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: message,
        })
      );
    }
  };
  //call save user API
  const saveUser = async () => {
    if (!checkInputErrors()) return;

    setLoading(true);

    const response = await updateUser(
      formData.id ? formData : { ...formData, id: userData.id }
    );

    const { status = 401, data = {} } = response || {};

    if (status === HTTP_STATUS.HTTP_OK) {
      if (isAdmin === false) dispatch(setUserData(data.data));
      if (teamChange) {
        saveTeamChanges();
      } else {
        if (userData.email === undefined && userData.includes("@")) {
          getUserData(userData);
        }
        setChange(false);
        setInitialFormdate();
        dispatch(
          setToast({
            open: true,
            severity: AppConstants.TOAST_SUCCESS,
            msg: AppConstants.PROFILE_UPDATED,
          })
        );
        setLoading(false);
        closeDrawer();
      }
    } else {
      const { details } = data;
      setLoading(false);
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: details,
        })
      );
    }
  };

  const changeUserStatus = async () => {
    const payload = {
      user_id: data.id,
      new_user_status:
        data.entityStatus === "ACTIVE" ? "DEACTIVATED" : "ACTIVE",
    };
    const response = await UpdateUserStatus(payload);
    const { status = 401, data: userData = {} } = response || {};
    if (status === HTTP_STATUS.HTTP_OK) {
      setIsDisableUserOpen(false);
      const {
        id = "",
        company = null,
        name: teamName = "",
      } = userData?.data?.team || {};
      const { id: companyId = "", name: companyName = "" } = company || {};
      setFormData({
        ...userData.data,
        team: teamName,
        company: companyName,
        companyId: companyId,
        teamId: id,
      });
      setData({
        ...userData.data,
        team: teamName,
        company: companyName,
        companyId: companyId,
        teamId: id,
      });
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_SUCCESS,
          msg:
            userData.entityStatus === "ACTIVE"
              ? "User is reactivated successfully"
              : "User is deactivated successfully",
        })
      );
    }
  };

  return (
    <>
      <div className="">
        {!isAdmin && (
          <div>
            <div className="flex pl-[50px] pr-[40px] bg-[#EAEBEC] pt-[45px] pb-[30px]">
              <div className="flex-1 w-fit">
                <UserNameText w={"200px"} className="!h-fit !ml-0">
                  My Account
                </UserNameText>
              </div>
              <div className="leading-8 mr-3">
                <TextValue
                  className="underline text-[#0494B4] cursor-pointer mx-1"
                  onClick={() => {
                    window.open(
                      "https://trestleiq.com/knowledge-base/how-do-i-add-additional-members-to-my-trestle-account/"
                    );
                  }}
                >
                  Add more users
                </TextValue>
              </div>
              <div
                onClick={() => {
                  setLoading(true);
                  logout();
                }}
                className="cursor-pointer leading-8"
              >
                Sign Out
              </div>
            </div>

            <div className="flex px-[45px] mt-3">
              <TabTextComponent
                selected={selectedTab === "DETAILS"}
                key={"DETAILS"}
                onClick={() => {
                  setSelectedTab("DETAILS");
                }}
                className="!h-[35px]"
              >
                Details
              </TabTextComponent>
              <TabTextComponent
                selected={selectedTab === "TEAMS"}
                key={"TEAMS"}
                onClick={() => {
                  setSelectedTab("TEAMS");
                }}
                className="!h-[35px]"
              >
                Team Info
              </TabTextComponent>
              <TabTextComponent
                selected={selectedTab === "BILLING_DETAILS"}
                key={"BILLING_DETAILS"}
                onClick={() => {
                  setSelectedTab("BILLING_DETAILS");
                }}
                className="!h-[35px]"
              >
                Billing Details
              </TabTextComponent>
            </div>
          </div>
        )}
        {isAdmin &&
          data.entityStatus &&
          (data?.entityStatus == "DEACTIVATED" ? (
            <div className="w-[100%] p-[12px] bg-red-600 h-[50px]">
              This User Is Deactivated and won't be able to log in.
            </div>
          ) : (
            <></>
          ))}
        <div
          className={`min-h-full  pr-[40px] bg-white ${
            isAdmin ? "pt-[10px] pl-0" : "pt-[60px] pl-[50px]"
          } `}
        >
          {selectedTab === "DETAILS" && (
            <div className="max-w-[550px]">
              <div className="mb-[40px]">
                <UserDetailSubHeader>
                  <span className="mr-3">{DownArrow}</span> Basic
                </UserDetailSubHeader>

                <InputDiv>
                  <InputTextField
                    name={"firstName"}
                    label={"First Name"}
                    value={formData}
                    error={formError}
                    type={"text"}
                    disabled={false}
                    onChange={onInputValueChange}
                    required={true}
                    maxLength={AppConstants.MAX_NAME_LENGTH}
                    height="50px"
                  />
                </InputDiv>

                <InputDiv>
                  <InputTextField
                    name={"lastName"}
                    label={"Last Name"}
                    value={formData}
                    error={formError}
                    type={"text"}
                    disabled={false}
                    onChange={onInputValueChange}
                    required={true}
                    maxLength={AppConstants.MAX_NAME_LENGTH}
                    height="50px"
                  />
                </InputDiv>

                {/* {!isAdmin && ( */}
                <>
                  <InputDiv>
                    <InputTextField
                      name={"company"}
                      label={"Company"}
                      value={formData}
                      error={formError}
                      type={"text"}
                      disabled={!isAdmin}
                      onChange={onInputValueChange}
                      required={false}
                      maxLength={AppConstants.MAX_APIKEY_NAME_LENGTH}
                      height="50px"
                    />
                  </InputDiv>

                  <InputDiv>
                    <InputTextField
                      name={"team"}
                      label={"Team"}
                      value={formData}
                      error={formError}
                      type={"text"}
                      disabled={!isAdmin}
                      onChange={onInputValueChange}
                      required={false}
                      maxLength={AppConstants.MAX_APIKEY_NAME_LENGTH}
                      height="50px"
                    />
                  </InputDiv>

                  {isAdmin && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        marginBottom: "15px",
                      }}
                    >
                      <div style={{ marginRight: "20px", flexGrow: 1 }}>
                        <InputTextField
                          name={"maxioCustomerId"}
                          label={"Maxio Customer ID"}
                          value={formData}
                          error={formError}
                          disabled={true}
                          type={"text"}
                          onChange={onInputValueChange}
                          required={false}
                          maxLength={AppConstants.MAX_NAME_LENGTH}
                          height="50px"
                        />
                      </div>
                      <div style={{ flexShrink: 0 }}>
                        <TrestleButton
                          onClick={() => {
                            setSelectMaxioCustomerID(true);
                          }}
                          label="Find/Refresh Maxio Customer"
                          type="primary"
                          className="!h-[50px] !leading-[40px] !py-[20px] !pl-[20px] !pr-[20px] !w-fit"
                        />
                      </div>
                    </div>
                  )}
                </>
                {/* )} */}
              </div>

              <div className="mb-[40px]">
                <UserDetailSubHeader>
                  <span className="mr-3">{DownArrow}</span> Contact
                </UserDetailSubHeader>

                <InputDiv>
                  <InputTextField
                    name={"email"}
                    label={"Email"}
                    value={formData}
                    error={formError}
                    disabled={true}
                    type={"text"}
                    onChange={onInputValueChange}
                    required={true}
                    maxLength={AppConstants.MAX_NAME_LENGTH}
                    height="50px"
                  />
                </InputDiv>

                <InputDiv>
                  <InputTextField
                    name={"phone"}
                    label={"Phone"}
                    value={formData}
                    error={formError}
                    disabled={!isAdmin}
                    type={"text"}
                    onChange={onInputValueChange}
                    required={true}
                    maxLength={AppConstants.MAX_PHONE_NUMBER_LENGTH}
                    height="50px"
                  />
                </InputDiv>
              </div>
              <div className="flex gap-[10px]">
                {isAdmin ? (
                  data.entityStatus == "ACTIVE" ? (
                    <TrestleButton
                      onClick={() => setIsDisableUserOpen(true)}
                      label="Deactivate User"
                      type="error"
                      className="max-w-[180px] !h-[40px] !py-0 !pt-0 mb-[40px]"
                    />
                  ) : (
                    <TrestleButton
                      onClick={() => setIsDisableUserOpen(true)}
                      label="Reactivate User"
                      type="primary"
                      className="max-w-[180px] !h-[40px] !py-0 !pt-0 mb-[40px]"
                    />
                  )
                ) : (
                  ""
                )}
                {isChange ? (
                  <div className="flex gap-[10px]">
                    <TrestleButton
                      onClick={onCancel}
                      label="Cancel"
                      type="secondry"
                      className="max-w-[180px] !h-[42px] !py-0 !pt-0"
                    />
                    <TrestleButton
                      onClick={saveUser}
                      label="Save Changes"
                      type="primary"
                      className="max-w-[180px] !h-[40px] !py-0 !pt-0 "
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>

              {!isAdmin && (
                <div className="pb-[20px]">
                  <UserDetailSubHeader>
                    <span className="mr-3">{DownArrow}</span> Password
                  </UserDetailSubHeader>

                  <TrestleButton
                    onClick={() => {
                      setDialog(true);
                    }}
                    label="Change Password"
                    type="primary"
                    className="max-w-[180px] !h-[40px] !py-0 !pt-0 mb-10"
                  />
                </div>
              )}
            </div>
          )}

          {selectedTab === "TEAMS" && (
            <div className="min-h-[75vh]">
              <Teams setLoading={setLoading} closeDrawer={closeDrawer} />
            </div>
          )}
          {selectedTab === "BILLING_DETAILS" && (
            <div className="min-h-[75vh]">
              <BillingDetailsTab
                setLoading={setLoading}
                setDialogOpen={closeDrawer}
              />
            </div>
          )}
        </div>
      </div>

      {
        <FindMaxioCustomerID
          open={selectMaxioCustomerID}
          setPopup={setSelectMaxioCustomerID}
          setIsLoading={setLoading}
          maxioCustomerData={formData}
          getUserData={getUserData}
          isAdmin={isAdmin}
        />
      }

      <DialogPopup
        open={showDialog}
        onClose={(val: any) => {
          setDialog(val);
        }}
        closeButton={showDialog}
        component={
          <ChangePassword
            closePopUp={(val: any) => {
              setDialog(val);
            }}
            setIsLoading={setLoading}
          />
        }
        className="!p-0 w-[700px]"
        // title={"Change Password"}
      />
      <DialogPopup
        open={isDisableUserOpen}
        onClose={() => setIsDisableUserOpen(false)}
        className="!p-0 w-[700px]"
        component={
          <>
            <div className="w-full py-8 px-10 bg-[#F3F5F6] text-[#163545] mb-6">
              {data.entityStatus === "ACTIVE" ? (
                <div className="text-xl font-bold flex">Deactivate User</div>
              ) : (
                <div className="text-xl font-bold flex">Reactivate User</div>
              )}
            </div>
            {data.entityStatus === "ACTIVE" ? (
              <Typography className="flex mt-[20px] py-8 px-10">
                Are you sure you want to deactivate this user? Once you
                deactivate this user, it will not be able to login to the
                portal.
              </Typography>
            ) : (
              <Typography className="flex mt-[20px] py-8 px-10">
                Are you sure you want to reactivate this user? Once you
                reactivate this user, it will be able to login with it's
                existing credentials and access the api key and other details.
              </Typography>
            )}

            <Box className="flex gap-[10px] mt-[20px] py-8 px-10">
              {data.entityStatus === "ACTIVE" ? (
                <TrestleButton
                  onClick={() => changeUserStatus()}
                  label="Deactivate User"
                  // disabled={disabledSubmit()}
                  type="error"
                  className="!h-[40px]!min-w-[fit-content]"
                />
              ) : (
                <TrestleButton
                  onClick={() => changeUserStatus()}
                  label="Reactivate User"
                  // disabled={disabledSubmit()}
                  type="primary"
                  className="!h-[40px]!min-w-[fit-content]"
                />
              )}
              <TrestleButton
                onClick={() => {
                  setIsDisableUserOpen(false);
                }}
                label="Cancel"
                // disabled={disabledSubmit()}
                type="secondry"
                className="!h-[40px]!min-w-[fit-content]"
              />
            </Box>
          </>
        }
      />
    </>
  );
}

export default UserDetails;

const InputDiv = styled.div`
  margin-bottom: 15px;
`;
