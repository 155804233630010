import {
  Navigate,
  Route,
  Routes as RoutesReactRouterDom,
} from "react-router-dom";
import { RouteConstants } from "../constants/RouteConstants";
import { Suspense } from "react";
import ProductAccess from "../components/AdminMode/ProductAccess/ProductAccess";
import AllUsers from "../components/AdminMode/AllUsers/index";
import YourUsage from "../components/UserMode/YourUsage/YourUsage";
import YourApiKey from "../components/UserMode/YourApiKey/YourApiKey";
import AdminUsage from "../components/AdminMode/AdminUsage/AdminUsage";
import PricingPage from "../components/UserMode/PricingPage";
import YourApiKeyNew from "../components/UserMode/YourApiKey/YourApiKeyNew";
import BatchUploadAdmin from "../components/AdminMode/BatchUploadsAdmin/BatchUploadAdmin";

export const AdminRoutes = () => {
  return (
    <Suspense fallback={<p>Loading</p>}>
      <RoutesReactRouterDom>
        <Route
          path={RouteConstants.ROUTE_ADMIN_ALL_USERS}
          element={<AllUsers />}
        />

        <Route
          path={RouteConstants.ROUTE_ADMIN_PRODUCT_ACCESS}
          element={<ProductAccess />}
        />

        <Route
          path={RouteConstants.ROUTE_ADMIN_BATCH_UPLOAD}
          element={<BatchUploadAdmin />}
        />
        <Route
          path={RouteConstants.ROUTE_ADMIN_USAGES}
          element={<AdminUsage />}
        />

        <Route path={RouteConstants.ROUTE_YOUR_USAGE} element={<YourUsage />} />
        <Route
          path={RouteConstants.ROUTE_YOUR_API_KEY}
          element={<YourApiKeyNew />}
        />

        <Route
          path={RouteConstants.ROUTE_PRICING_PAGE}
          element={<PricingPage />}
        />

        <Route
          path={"*"}
          element={<Navigate to={RouteConstants.ROUTE_ADMIN_HOME} />}
        />
      </RoutesReactRouterDom>
    </Suspense>
  );
};
